<!--
 * @Description: 审核退款
 * @Author: 琢磨先生
 * @Date: 2022-08-29 00:39:25
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2023-03-12 13:51:16
-->
<template>
  <el-dialog
    title="退款"
    v-model="visible"
    width="1000px"
    :destroy-on-close="true"
    :show-close="true"
    draggable
    top="10vh"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    @closed="onClosed"
  >
    <el-scrollbar v-loading="loading">
      <div style="min-height:500px;">
        <el-descriptions title="订单详情" v-if="model.order">
          <el-descriptions-item label="单号：">
            <el-link
              type="primary"
              @click="showOrderDtl"
              :underline="false"
            >{{model.order.order_no}}</el-link>
          </el-descriptions-item>
          <el-descriptions-item label="订单类型：">{{model.order.type_text}}</el-descriptions-item>
          <el-descriptions-item
            label="联系人："
          >{{model.order.contact_name}}、{{model.order.mobile_phone}}</el-descriptions-item>

          <el-descriptions-item label="已付款：">
            <b style="color: #fa0;">¥{{model.order.decimal_receipt_amount}}</b>
          </el-descriptions-item>
          <el-descriptions-item label="还需付款：">
            <b style="color: #f5222d;">¥{{model.order.decimal_pay_amount}}</b>
          </el-descriptions-item>
          <el-descriptions-item label="状态：">{{model.order.status_text}}</el-descriptions-item>
          <el-descriptions-item label="下单时间：">{{model.order.create_at}}</el-descriptions-item>
        </el-descriptions>

        <el-divider>房间信息</el-divider>
        <el-table :data="model.order.order_stores" border v-if="model.order">
          <el-table-column label="房源" width="400">
            <template #default="scope">
              <el-row>
                <el-col :span="8">
                  <el-image
                    :src="scope.row.house_logo_url"
                    fit="cover"
                    style="width:80px;height:80px;"
                  ></el-image>
                </el-col>
                <el-col :span="15">{{scope.row.house_name}}</el-col>
              </el-row>
            </template>
          </el-table-column>
          <el-table-column label="房号" width="140" prop="house_no"></el-table-column>
          <el-table-column label="预订日期" width="160">
            <template #default="scope">
              <div>入住：{{scope.row.checkin_date}}</div>
              <div>离店：{{scope.row.checkout_date}}</div>
            </template>
          </el-table-column>
          <el-table-column label="房费">
            <template #default="scope">
              <b style="color: #053dc8;">￥{{scope.row.decimal_amount}}</b>
              <div>
                违约金：
                <b style="color: #f5222d;">￥{{scope.row.decimal_break_amount}}</b>
              </div>
            </template>
          </el-table-column>
          <el-table-column label="状态">
            <template #default="scope">
              <el-tag type="info">{{scope.row.status_text}}</el-tag>
            </template>
          </el-table-column>
        </el-table>
        <el-divider>收/退款</el-divider>
        <el-table :data="model.order.receipts" border v-if="model.order">
          <el-table-column label="收支" prop="in_out_text" width="100"></el-table-column>
          <el-table-column label="项目" prop="type_text" width="140"></el-table-column>
          <el-table-column label="支付方式" prop="payment_term_name" width="140"></el-table-column>
          <el-table-column label="金额" width="140">
            <template
              #default="scope"
            >{{ scope.row.in_or_out == 1 ? "-" : "+" }}{{ scope.row.decimal_amount }}</template>
          </el-table-column>
          <el-table-column label="日期" prop="date" width="140"></el-table-column>
          <el-table-column label="状态" prop="status_text" width="140">
            <template #default="scope">
              <el-link
                type="primary"
                :underline="false"
                v-if="scope.row.status==5"
              >{{scope.row.status_text}}</el-link>
              <el-link :underline="false" v-else>{{scope.row.status_text}}</el-link>
            </template>
          </el-table-column>
          <el-table-column label="备注" prop="remark" width="220"></el-table-column>
        </el-table>

        <el-divider>申请退款</el-divider>

        <el-table :data="model.refund_receipts" border v-if="model.refund_receipts">
          <!-- <el-table-column type="selection" :selectable="selectable" width="50"></el-table-column> -->
          <el-table-column label="项目" prop="receipt.type_text" width="140"></el-table-column>
          <el-table-column label="支付方式" prop="receipt.payment_term_name" width="160"></el-table-column>
          <el-table-column label="退款金额" width="140">
            <template #default="scope">￥{{ scope.row.receipt.decimal_amount }}</template>
          </el-table-column>
          <el-table-column label="状态" prop="status_text" width="140">
            <template #default="scope">{{scope.row.status===0?'--':scope.row.status_text}}</template>
          </el-table-column>
          <el-table-column label="处理备注" prop="check_remark"></el-table-column>
          <el-table-column label="退款处理" width="200">
            <template #default="scope">
              <div v-if="scope.row.status==0">
                <el-radio-group v-model="scope.row.agreeable">
                  <el-radio :label="true">同意</el-radio>
                  <el-radio :label="false">拒绝</el-radio>
                </el-radio-group>
                <el-input v-model="scope.row.check_remark" type="textarea" placeholder="备注信息"></el-input>
              </div>
              <div v-if="scope.row.status==5 || scope.row.status==15">
                <el-button
                  type="success"
                  icon="RefreshRight"
                  size="small"
                  @click="onRetryRefund(scope.row)"
                  v-power="'seller/v1/HouseRefund/pay'"
                  :loading="saving"
                >重试</el-button>
              </div>
            </template>
          </el-table-column>
          <!-- <el-table-column label="创建时间" prop="create_at" width="180"></el-table-column> -->
        </el-table>

        <!-- <el-form :model="form" ref="form" :rules="rules" style="margin-top:20px;">
          <el-form-item label>
            <el-input v-model="form.remark" type="textarea" placeholder="备注信息"></el-input>
          </el-form-item>
        </el-form>-->
      </div>
    </el-scrollbar>

    <template #footer v-if="model.refund_receipts">
      <el-button type="primary" :loading="saving" @click="onSubmit" v-power="'seller/v1/houseRefund/confirm'" v-if="model.refund_receipts.find(o=>o.status==0)">确定</el-button>
    </template>
  </el-dialog>
  <!-- <order-dtl :order_id="current_order_id" @close="current_order_id=null"></order-dtl> -->
</template>

<script>
// import OrderDtl from "../order/order_dtl.vue";

export default {
  //   components: {
  //     OrderDtl,
  //   },
  data() {
    return {
      visible: false,
      loading: false,
      saving: false,
      model: {},
      current_order_id: null,
      //选择的数据
      selection_list: [],
      //表单
      form: {},
      rules: {},
    };
  },
  props: ["id"],
  emits: ["close"],
  created() {
    // this.id=25;
    this.loadData();
  },
  watch: {
    id: {
      handler() {
        if (this.id) {
          this.visible = true;
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    loadData() {
      this.loading = true;
      this.$http
        .get("seller/v1/houseRefund/dtl?id=" + this.id)
        .then((res) => {
          if (res.code == 0) {
            this.model = res.data;
            this.model.refund_receipts.forEach((item) => {
              item.agreeable = true;
            });
          }
        })
        .finally(() => {
          this.loading = false;
        });
    },

    /**
     * 关闭
     */
    onClosed(result) {
      this.$emit("close", result);
    },

    /**
     * 查看订单详情
     */
    showOrderDtl() {
      //   this.current_order_id = this.model.order_id;
    },

    // /**
    //  *
    //  * @param {*} item
    //  */
    // selectable(row) {
    //   return row.status == 0;
    // },

    /**
     * 确定
     */
    onSubmit() {
      this.$confirm(`您是否确定提交？`, "温馨提示").then(() => {
        // this.form.refund_receipt_ids = this.selection_list.map((x) => x.id);
        this.form = {
          refund_id: this.model.id,
        };
        this.form.refund_receipts = this.model.refund_receipts.map((x) => {
          return {
            id: x.id,
            agreeable: x.agreeable,
            check_remark: x.check_remark,
          };
        });
        this.saving = true;
        this.$http
          .post("seller/v1/houseRefund/confirm", this.form)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.onClosed(true);
            }
          })
          .finally(() => {
            this.saving = false;
          });
      });
    },

    /**
     * 重试退款
     */
    onRetryRefund(item) {
      item.saving = true;
      this.$confirm(`您是否确定重试退款？`, "温馨提示").then(() => {
        this.$http
          .get("seller/v1/houseRefund/pay?id=" + item.id)
          .then((res) => {
            if (res.code == 0) {
              this.$message.success(res.msg);
              this.loadData();
            }
          })
          .finally(() => {
            item.saving = false;
          });
      });
    },
  },
};
</script>

<style  scoped>
</style>